<template>
	<div class="jobType">
		<div class="tabs_box">
			<el-tabs style="width: 100%" v-model="activeName" @tab-click="tabClick">
				<!-- 职族配置 -->
				<el-tab-pane label="职族" name="three" v-if="permissionControl('OccupationFamily')">
					<profession-family :activeName="activeName" ref="professionFamily" pageName="OccupationFamily"></profession-family>
				</el-tab-pane>
				<el-tab-pane label="职类" name="first" v-if="permissionControl('OccupationClass')"> </el-tab-pane>
				<el-tab-pane label="职类子类" name="second" v-if="permissionControl('OccupationSubClass')"> </el-tab-pane>
				<!-- <el-tab-pane label="职位职级表" name="four">
                    <rank-table :activeName="activeName" ref="rankTable"></rank-table>
                </el-tab-pane> -->
			</el-tabs>
			<div class="tabBtn"></div>
		</div>
		<div class="search_box" v-show="activeName === 'second' || activeName === 'first'">
			<el-form :inline="true" :model="searchForm" class="demo-form-inline">
				<el-form-item class="formLabel" label="职类" style="margin-right: 10px">
					<el-input
						v-model="searchForm.className"
						size="small"
						style="width: 180px"
						placeholder="请输入搜索职类"
						clearable
						@keydown.native.enter="query"
					></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="职类子类" style="margin-right: 10px" v-show="activeName === 'second'">
					<el-input
						v-model="searchForm.subclassName"
						size="small"
						style="width: 180px"
						placeholder="请输入搜索职类子类"
						clearable
						@keydown.native.enter="query"
					></el-input>
				</el-form-item>
				<el-button type="primary" @click="query" size="small" icon="el-icon-search" style="margin: 5px 0 0 5px; height: 32px">查询</el-button>
				<el-form-item class="fr" style="margin-right: 0">
					<el-button
						size="small"
						icon="el-icon-plus"
						type="primary"
						style="margin: 5px 0 0 0; height: 32px"
						@click="handleAdd"
						v-if="permissionControlBtns(pageName, 'Add')"
						>添加
					</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div v-show="activeName === 'second' || activeName === 'first'">
			<table-base
				:tableData="tableData"
				:tableConfig="activeName === 'first' ? tableConfig : tableConfig1"
				:dataTotal="total"
				:loading="loading"
				:currentPage="searchForm.pageNo"
				:subPageName="activeName === 'first' ? 'OccupationClass' : activeName === 'second' ? 'OccupationSubClass' : 'OccupationFamily'"
				@pageSizeChange="handleSizeChange"
				@pageNumberChange="currentPageChange"
				@edit="handleEdit"
				@remove="handleDel"
			>
			</table-base>
		</div>

		<el-dialog
			width="450px"
			:title="dialogTitle"
			top="30vh"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<el-form :model="activeName === 'first' ? formModel : formSubModel" ref="configForm" label-width="110px">
				<el-form-item label="id" style="display: none" prop="id"></el-form-item>
				<el-form-item
					:label="activeName === 'first' ? '职族' : '职类'"
					:prop="activeName === 'first' ? 'familyId' : 'classId'"
					:rules="
						activeName === 'first'
							? { required: true, message: '请选择职族', trigger: 'change' }
							: { required: true, message: '请选择职类', trigger: 'change' }
					"
				>
					<el-select
						placeholder="请选择职族"
						style="width: 80%; margin: 0 5px 5px 0"
						v-if="activeName === 'first'"
						filterable
						v-model="formModel.familyId"
					>
						<el-option v-for="item in jobList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
					<el-select placeholder="请选择职类子类" style="width: 80%" v-else filterable v-model="formSubModel.classId">
						<el-option v-for="item in jobClassList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
				<div v-if="activeName === 'first'">
					<el-form-item
						v-for="(item, index) in formModel.classNames"
						:label="index === 0 ? '职类名称' : ''"
						:prop="'classNames.' + index + '.value'"
						:key="item.key"
						:rules="{ required: true, message: '请输入职类名称', trigger: 'blur' }"
					>
						<el-input v-model="item.value" placeholder="请输入职类名称" style="width: 80%; margin: 0 5px 5px 0"> </el-input>
						<el-button icon="el-icon-delete" type="text" style="color: red" v-show="index > 0" @click.prevent="deleteItem(item)">
						</el-button>
					</el-form-item>
				</div>
				<div v-else>
					<el-form-item
						v-for="(item, index) in formSubModel.subClassNames"
						:label="index === 0 ? '职类子类名称' : ''"
						:prop="'subClassNames.' + index + '.value'"
						:key="item.key"
						:rules="{ required: true, message: '请输入职类子类名称', trigger: 'blur' }"
					>
						<el-input v-model="item.value" placeholder="" style="width: 80%; margin: 0 5px 10px 0"></el-input>
						<el-button icon="el-icon-delete" type="text" style="color: red" v-show="index > 0" @click.prevent="deleteItem(item)">
						</el-button>
					</el-form-item>
				</div>
				<el-form-item style="transform: translateY(-15px)">
					<el-button
						type="text"
						@click="addItem"
						icon="el-icon-plus"
						size="small"
						v-show="activeName === 'first' ? !formModel.id : !formSubModel.id"
					>
						添加
					</el-button>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import tableBase from '../components/tableBase.vue';
import professionFamily from '../rankSystem/professionFamily.vue'; // 职族
// import rankTable from '../rankSystem/rankTable.vue';
export default {
	components: {
		tableBase,
		professionFamily // 职族
		// rankTable// 职位职级表
	},
	props: { pageName: {} },
	data() {
		return {
			activeName: 'three',
			dialogTitle: '',
			dialogVisible: false,
			loading: false,
			total: 0,
			searchForm: {
				className: '',
				subclassName: '',
				pageNo: 1,
				pageSize: 20
			},
			typeList: [],
			tableData: [],
			jobList: [],
			jobClassList: [],
			tableConfig: [
				{
					label: '职族',
					prop: 'familyName'
				},
				{
					label: '职类',
					prop: 'className'
				}
				// {
				// 	label: '修改人',
				// 	prop: 'userName'
				// },
				// {
				// 	label: '修改时间',
				// 	prop: 'updateTime'
				// }
			],
			tableConfig1: [
				{
					label: '职族',
					prop: 'familyName'
				},
				{
					label: '职类',
					prop: 'className'
				},
				{
					label: '职类子类',
					prop: 'subclassName'
				}
				// {
				// 	label: '修改人',
				// 	prop: 'userName'
				// },
				// {
				// 	label: '修改时间',
				// 	prop: 'updateTime'
				// }
			],
			formModel: {
				id: '',
				familyId: '',
				oldfamilyId: '',
				classNames: [{ value: '' }]
			},
			formSubModel: {
				id: '',
				classId: '',
				oldclassId: '',
				subClassNames: [{ value: '' }]
			}
		};
	},
	methods: {
		tabClick() {
			this.searchForm.pageNo = 1;
			this.searchForm.className = '';
			this.searchForm.subclassName = '';
			this.getList();
		},
		getList() {
			this.loading = true;
			if (this.activeName === 'first') {
				this.$axios
					.post('/v1/profession/class/list', {
						request: this.searchForm,
						sign: ''
					})
					.then((res) => {
						if (res && res.list) {
							this.total = res.totalNum;
							this.tableData = res.list;
							this.loading = false;
						}
					});
			} else {
				this.$axios
					.post('/v1/profession/sub/class/list', {
						request: this.searchForm,
						sign: ''
					})
					.then((res) => {
						this.total = res.totalNum;
						this.tableData = res.list;
						this.loading = false;
					});
			}
			this.loading = false;
		},
		query() {
			this.searchForm.pageNo = 1;
			this.getList();
		},
		resetForm() {
			// eslint-disable-next-line no-unused-expressions
			this.$refs.configForm && this.$refs.configForm.resetFields();
		},
		// 获取职族下拉数据
		getjobFamily() {
			this.jobList = [];
			this.$axios
				.post('/v1/profession/family/list', {
					request: {},
					sign: ''
				})
				.then((res) => {
					if (res) {
						res.list.forEach((element) => {
							const obj = {};

							obj.id = element.id;
							obj.name = element.name;
							this.jobList.push(obj);
						});
					}
				});
		},
		// 获取职类下拉数据
		getjobClass() {
			this.jobClassList = [];
			this.$axios
				.post('/v1/profession/class/list', {
					request: {
						pageNo: 1,
						pageSize: 999999,
						className: ''
					},
					sign: ''
				})
				.then((res) => {
					if (res && res.list) {
						res.list.forEach((element) => {
							const obj = {};

							obj.id = element.id;
							obj.name = element.className;
							this.jobClassList.push(obj);
						});
					}
				});
		},
		handleAdd() {
			this.dialogVisible = true;
			if (this.activeName === 'first') {
				this.dialogTitle = '新增职类';
				this.getjobFamily();
				// eslint-disable-next-line eqeqeq
				if (this.$refs.configForm != undefined) {
					this.$refs.configForm.resetFields();
				}
				// setTimeout(() => {
				//     this.resetForm();
				// }, 500);
				this.formModel = {
					id: '',
					oldfamilyId: '',
					familyId: '',
					classNames: [{ value: '' }]
				};
			} else {
				this.dialogTitle = '新增职类子类';
				this.getjobClass();
				// eslint-disable-next-line eqeqeq
				if (this.$refs.configForm != undefined) {
					this.$refs.configForm.resetFields();
				}
				// setTimeout(() => {
				//     this.resetForm();
				// }, 500);
				this.formSubModel = {
					id: '',
					classId: '',
					oldclassId: '',
					subClassNames: [{ value: '' }]
				};
			}
		},
		handleEdit(row) {
			if (this.activeName === 'first') {
				this.dialogTitle = '职类编辑';
				this.getjobFamily();
				// this.resetForm();
				setTimeout(() => {
					this.formModel.id = row.id;
					this.formModel.oldfamilyId = row.familyId;
					this.formModel.familyId = row.familyId;
					this.dialogVisible = true;
				}, 300);
				this.formModel.classNames = [{ value: row.className }];
			} else {
				this.dialogTitle = '职类子类编辑';
				this.getjobClass();
				// this.resetForm();
				setTimeout(() => {
					this.formSubModel.id = row.id;
					this.formSubModel.oldclassId = row.classId;
					this.formSubModel.classId = String(row.classId);
					this.dialogVisible = true;
				}, 300);
				this.formSubModel.subClassNames = [{ value: row.subclassName }];
			}
		},
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				if (this.activeName === 'first') {
					this.$axios
						.post('/v1/profession/class/del', {
							request: { id: row.id },
							sign: ''
						})
						.then((res) => {
							// eslint-disable-next-line eqeqeq
							if (res && res.code == 0) {
								this.$message.success('删除成功!');
								this.getList();
							}
						});
				} else {
					this.$axios
						.post('/v1/profession/sub/class/del', {
							request: { id: row.id },
							sign: ''
						})
						.then((res) => {
							// eslint-disable-next-line no-unused-expressions, eqeqeq
							if (res && res.code == 0) {
								this.$message.success('删除成功!');
								this.getList();
							}
						});
				}
			});
		},
		handleSizeChange(data) {
			this.searchForm.pageSize = data;
			this.getList();
		},
		currentPageChange(data) {
			this.searchForm.pageNo = data;
			this.getList();
		},
		// dialogSubmit (data) {
		//     console.log(data);
		// },
		addItem() {
			// eslint-disable-next-line no-unused-expressions
			this.activeName === 'first' && this.formModel.classNames.push({ value: '', key: Date.now() });
			// eslint-disable-next-line no-unused-expressions
			this.activeName === 'second' && this.formSubModel.subClassNames.push({ value: '', key: Date.now() });
		},
		deleteItem(item) {
			// eslint-disable-next-line init-declarations
			let index;

			if (this.activeName === 'first') {
				index = this.formModel.classNames.indexOf(item);
				if (index !== -1) {
					this.formModel.classNames.splice(index, 1);
				}
			} else {
				index = this.formSubModel.subClassNames.indexOf(item);
				if (index !== -1) {
					this.formSubModel.subClassNames.splice(index, 1);
				}
			}
		},
		submitForm() {
			this.$refs.configForm.validate((valid) => {
				if (valid) {
					const params = {
						request: this.activeName === 'first' ? this.formModel : this.formSubModel,
						sign: ''
					};

					if (this.activeName === 'first') {
						if (
							this.$store.state.app.range.familyIds &&
							this.$store.state.app.range.familyIds.includes(Number(this.formModel.oldfamilyId))
						) {
							this.$message.warning('当前职族正在进行人岗匹配，请完成后修改');
							return;
						}
						const classNames = [];

						this.formModel.classNames.forEach((item) => {
							classNames.push(item.value);
						});
						params.request.classNames = classNames;
						this.$axios.post('/v1/profession/class/save', params).then((res) => {
							if (res.code === 0) {
								// eslint-disable-next-line no-unused-expressions
								this.formModel.id && this.$message.success('修改职类成功');
								// eslint-disable-next-line no-unused-expressions
								!this.formModel.id && this.$message.success('新增职类成功');
								this.formModel.classNames = [{ value: '' }];
								this.getList();
							}
						});
					} else {
						if (
							this.$store.state.app.range.classIds &&
							this.$store.state.app.range.classIds.includes(Number(this.formSubModel.oldclassId))
						) {
							this.$message.warning('当前职族正在进行人岗匹配，请完成后修改');
							return;
						}
						const subClassNames = [];

						this.formSubModel.subClassNames.forEach((item) => {
							subClassNames.push(item.value);
						});
						params.request.subClassNames = subClassNames;
						this.$axios.post('/v1/profession/sub/class/save', params).then((res) => {
							if (res.code === 0) {
								// eslint-disable-next-line no-unused-expressions
								this.formSubModel.id && this.$message.success('修改职类子类成功');
								// eslint-disable-next-line no-unused-expressions
								!this.formSubModel.id && this.$message.success('新增职类子类成功');
								this.formSubModel.subClassNames = [{ value: '' }];
								this.getList();
							}
						});
					}
					this.dialogVisible = false;
				}
			});
		}
	},
	mounted() {
		this.getList();
		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: 'three', key: 'OccupationFamily' },
			{ name: 'first', key: 'OccupationClass' },
			{ name: 'second', key: 'OccupationSubClass' }
		]);
	}
};
</script>
<style lang="scss">
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}

.jobType {
	.search_box {
		margin: 10px 0;
	}

	.tabs_box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// padding-right: 32px;
	}

	.tabBtn {
		position: absolute;
		right: 20px;
		top: 20px;
	}

	.add_btn {
		height: 35px;
		width: 80px;
		line-height: 10px;
		// font-size: 14px;
	}

	.el-dialog__body {
		padding: 20px 20px 0px;
		margin-bottom: -10px;
	}

	.el-form-item {
		margin-bottom: 15px;
	}

	.el-form-item__error {
		top: 90%;
	}
}
</style>

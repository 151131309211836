<template>
  <!--  -->
  <el-dialog :title="title"
              top="30vh"
             :visible.sync="dialogVisible"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             center
            :width="width?width:'500px'">
    <el-form :model="formModel"
             ref="configForm"
             label-width="80px">
      <el-row :gutter="16">
        <el-col :span="item.span?item.span:8"
                v-for="(item,index) in config"
                :key="index">
          <el-form-item :prop="item.prop"
                        :rules="item.rules"
                        :label="item.label">
            <!--输入框表单类型-->
            <el-input v-if="item.type ==='text'"
                      v-model="formData[item.prop]"
                      :placeholder="item.placeholder?item.placeholder:'请输入'"></el-input>
            <!--文本域表单类型-->
            <el-input v-if="item.type === 'textarea'"
                      type="textarea"
                      v-model="formData[item.prop]"
                      :placeholder="item.placeholder?item.placeholder:'请输入'"></el-input>
            <!--下拉选择类型-->
            <el-select v-if="item.type === 'select'"
                       v-model="formData[item.prop]"
                       clearable
                       filterable
                       :placeholder="item.placeholder?item.placeholder:'请选择'">
              <el-option v-for="option in item.data"
                         :key="option.dictId"
                         :label="option.dictName"
                         :value="option.dictId">
              </el-option>
            </el-select>

            <el-date-picker v-if="item.type === 'datepicker'"
                            v-model="formData[item.prop]"
                            type="date"
                            :placeholder="item.placeholder?item.placeholder:'请选择日期'">
            </el-date-picker>

          </el-form-item>
        </el-col>
      </el-row>
      <slot></slot>
    </el-form>

    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                  :loading="isCommit"
                 @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'base-dialog-form',
  props: [
    'title',
    'width',
    'visible',
    'config',
    'formData',
    'isCommit'
  ],
  data () {
    return {
      formModel: {},
      dialogVisible: false,
      dialogTitle: ''
    };
  },
  mounted () {
    // 将组件上的属性赋值给当前组件内变量，因为props只能单向绑定,还需要监听属性值变化进行父子组件间交互
    this.formModel = this.formData;
    this.dialogVisible = this.visible;
    this.dialogTitle = this.title;
  },
  methods: {
    // 提交表单数据
    submitForm () {
      this.$refs.configForm.validate((valid) => {
        if (valid) {
          // 让父组件接收到响应数据
          this.$emit('submit', this.formModel);
          // 关闭模态框
          // this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    // 重置表单状态
    resetForm () {
      if (this.$refs.configForm) {
        this.$refs.configForm.resetFields();
      }
    },
    // 展示模态框
    showDialog (val) {
      this.dialogVisible = val;
    }
  },
  watch: {
    /* 实现表单数据的绑定，实时接收父组件的数据变化*/
    formData () {
      this.formModel = this.formData;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-input {
  width: 100% !important;
}

.el-select {
  width: 100% !important;
}
</style>

<!--  新增岗位说明书  -->
<template>
	<el-dialog
		fullscreen
		:title="dialogTitle"
		top="30vh"
		center
		:visible="visible"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		@close="handleClose"
	>
		<formItem
			v-if="visible"
			ref="formItemAddBook"
			:formItems="dialogItems"
			@cancel="$emit('close')"
			@submit="submitForm"
			:defaultData="formModel"
			:type="formType"
			style="margin: 20px 0"
		>
			<div
				v-for="(item, inx) in dialogList"
				:key="inx"
				style="padding: 20px; border: 1px dashed #bfbfbf; margin-left: 110px; margin-bottom: 10px"
			>
				<formItem :ref="`formItem${inx}`" :formItems="dialogDutyItems" :defaultData="item" :type="formType" :showFooter="false" />
				<div style="text-align: center; cursor: pointer" v-if="dialogList.length > 1 && !isView">
					<i class="el-icon-delete" style="color: red" @click="dialogList.splice(inx, 1)"></i>
				</div>
			</div>
			<div class="addFunctions" v-show="!isView" @click="dialogList.push({ dutyName: `职责${dialogList.length + 1}` })">
				<i class="el-icon-plus" style="margin-right: 4px"></i>新增职责
			</div>
			<div style="margin-left: 42px">任职资格</div>
			<formItem
				ref="formItem_sen"
				:formItems="dialogSenItems"
				:defaultData="dialogSenModel"
				:type="formType"
				:showFooter="false"
				style="padding: 20px; border: 1px dashed #bfbfbf; margin-left: 110px; margin-bottom: 10px"
			/>
		</formItem>
	</el-dialog>
</template>

<script>
/* 新增岗位说明书 */
import formItem from '@/views/components/components-form-item/index.vue';
export default {
	name: 'addJob',
	components: {
		formItem
	},

	props: {
		visible: { type: Boolean, default: false, require: true },
		formType: { type: String },
		jobId: { type: [Number, String] },
		orgId: { type: [Number, String] }
	},
	computed: {
		dialogTitle() {
			let title = '编辑';

			switch (this.formType) {
				case 'view':
					title = '岗位说明书';
					break;
				case 'add':
					title = '添加岗位说明书';
					break;
				case 'edit':
					title = '修改岗位说明书';
					break;

				default:
					break;
			}

			return title;
		},
		isView() {
			return this.formType === 'view';
		}
	},
	data() {
		return {
			dialogList: [{ dutyName: '职责1' }],
			// 岗位说明书
			dialogItems: [
				{ label: '岗位名称', key: 'jobName', type: 'input', span: 8, disabled: true },
				{ label: '所属职位', key: 'officeName', type: 'input', span: 8, disabled: true },
				{ label: '职级代码', key: 'officeCode', type: 'input', span: 8, disabled: true },
				{ label: '职级', key: 'officeLevel', type: 'input', span: 8, disabled: true },
				{ label: '所属组织', key: 'orgName', type: 'input', span: 8, disabled: true },
				// { label: '岗位编制', key: 'employeesNumber', type: 'input', span: 8, disabled: true },
				// { label: '晋升方向', key: 'jobPromote', type: 'select', span: 8 },
				// { label: '可轮换岗位', key: 'rotateJobName', type: 'select', span: 8 },
				{
					label: '岗责承接',
					key: 'functionId',
					type: 'cascader',
					async: () => this.$axios.post('/v1/profession/findOrgFunctionByOrgId', { request: { orgId: this.orgId } }),
					// .then((res) =>
					// 	res.map(({ functionDesc, functionLevel, id }) => ({ dictId: id, dictName: `${functionLevel}-${functionDesc}` }))
					// ),
					props: {
						disabled: 'disable',
						checkStrictly: true,
						expandTrigger: 'hover',
						children: 'orgFunctions',
						value: 'id',
						label: 'functionDesc'
						// emitPath: false
					},
					span: 8
					// change: (formData, _, item) => {
					// 	const familyIdOpts = this.$store.state.setData.options?.funtionId;
					// 	const manOpt = familyIdOpts.find(({ label }) => label === '管理族');

					// }
				},
				{ label: '岗位职责', key: 'gwzz', type: 'empty', style: 'height:40px' /* , labelPosition: 'left' */ },
				{ label: '关键职责概述', labelWidth: '206px', key: 'duty', type: 'textarea', rows: 3, autosize: false }
			],
			// 职责
			dialogDutyItems: [
				{ label: '', labelWidth: '110px', key: 'dutyName', type: 'input', span: 8, placeholder: '职责' },
				{ label: '', labelWidth: '30px', key: 'dutyDetail', type: 'input', span: 16, placeholder: '详情' }
			],
			dialogSenModel: {},
			// 资格
			dialogSenItems: [
				// { label: '任职资格', key: 'rzzg', type: 'empty' },
				{ label: '基本条件', key: 'jbtj', type: 'empty', style: 'height:40px', labelPosition: 'left' },
				{ label: '教育水平', key: 'educational', type: 'input', span: 8 },
				{ label: '工作经验', key: 'experience', type: 'input', span: 8 },
				{ label: '语言能力', key: 'tongue', type: 'input', span: 8 },
				{ label: '专业知识', key: 'major', type: 'input', span: 8 },
				{ label: '个人素质', key: 'quality', type: 'input', span: 8 },
				{ label: '其他', key: 'other', type: 'input', span: 8 },
				{ label: '岗位胜任要求', key: 'gwyq', type: 'empty', style: 'height:40px', labelPosition: 'left' },
				{ label: '知识', key: 'knowledge', type: 'textarea', rows: 3, autosize: false, span: 12 },
				{ label: '技能', key: 'skill', type: 'textarea', rows: 3, autosize: false, span: 12 }
				// { label: '', labelWidth: '30px', key: 'detail', type: 'input', span: 16, placeholder: '详情' }
			],
			formModel: {}
			// dialogTitle: '添加',
		};
	},
	created() {},
	watch: {
		jobId(val) {
			if (val) {
				this.getJobDetailById(val);
			}
		}
		// orgId(val) {
		// 	if (val) {
		// 		this.getOfficeByOrgId(val);
		// 	}
		// }
	},
	updated() {},
	mounted() {},
	methods: {
		// 提交岗位说明书
		submitForm(form) {
			form.validate((valid) => {
				if (valid) {
					const params = { ...this.formModel };

					if (Array.isArray(params.functionId) && params.functionId?.length) {
						params.type = params.functionId?.length;
						params.functionId = params.functionId?.slice(-1)?.[0];
					} else {
						params.functionId = null;
					}
					return this.$axios
						.post('/v1/profession/saveJobInstruction', { request: params })
						.then((res) => {
							if (res.code) {
								return;
							}
							this.$emit('close');
							this.$message.success('保存成功');
							this.formModel = {};
							this.dialogSenModel = {};
							this.dialogList = [{ dutyName: '职责1' }];
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							this.$emit('close');
						});
				}
			});
		},
		// 岗位及岗位说明书详情查询
		getJobDetailById(jobId) {
			return this.$axios
				.post('/v1/profession/instructionDetailByJobId', { request: { jobId } })
				.then((res) => {
					if (res.code) {
						return;
					}
					this.$delete(res, '_responseStatusCode');
					this.dialogList = res.orgJobDutys;
					this.dialogSenModel = res;
					this.formModel = res;

					return res;
				})
				.catch(() => {});
		},
		// 岗责承接
		getOfficeByOrgId(id) {
			return this.$axios
				.post('/v1/profession/findOfficeByOrgId', { request: { orgId: id } })
				.then((res) => {
					if (res.code) {
						return {};
					}
					return res;
				})
				.catch(() => {});
		},
		handleClose() {
			this.$emit('close');
			this.formModel = {};
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	// padding: 20px;
	.addClass {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 30px 0;
		justify-content: center;
	}
	.addFunctions {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 30px 0;
		justify-content: center;
	}
}
</style>

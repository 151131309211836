<template>
	<div>
		<div class="tabs_box">
			<el-tabs style="width: 100%" v-model="activeName" @tab-click="tabClick">
				<el-tab-pane label="职位" name="1" v-if="permissionControl('JobPosition')">
					<!-- 职位 -->
					<position ref="station" v-if="activeName == '1'" pageName="JobPosition"></position>
				</el-tab-pane>
				<el-tab-pane label="岗位" name="2" v-if="permissionControl('Jobs')">
					<!-- <div class="search_box">
						<el-form :inline="true" :model="searchForm" class="demo-form-inline">
							<el-form-item class="formLabel" label="岗位" style="margin-right: 15px">
								<el-input v-model="searchForm.name" size="small" placeholder="请输入岗位名称"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" size="small" @click="query" style="tansform: translateY(-1px)" icon="el-icon-search"
									>查询</el-button
								>
							</el-form-item>
							<el-form-item style="float: right; margin-right: 0">
								<el-button type="primary" size="small" @click="handleAdd" style="tansform: translateY(-1px)" icon="el-icon-plus"
									>添加</el-button
								>
							</el-form-item>
						</el-form>
					</div>
					<table-base
						:tableData="tableData"
						:tableConfig="tableConfig"
						:dataTotal="total"
						:loading="loading"
						@pageSizeChange="handleSizeChange"
						:currentPage="searchForm.pageNo"
						@pageNumberChange="currentPageChange"
						@edit="handleEdit"
						@remove="handleDel"
					></table-base> -->
					<jobs pageName="Jobs" />
				</el-tab-pane>
				<!-- 职位岗位绑定 -->
				<!-- <el-tab-pane label="职位岗位绑定" name="3">
					<job-bind ref="bind" v-if="activeName == '3'"></job-bind>
				</el-tab-pane> -->
			</el-tabs>
			<!--<div class="tabBtn">
        <el-button size="small"
                   icon="el-icon-plus"
                   type="primary"
                   @click="handleAdd">添加
        </el-button>
      </div>!-->
		</div>
		<!-- add/edie -->
		<dialog-form :title="formTitle" ref="dialogForm" :config="formConfig" :form-data="formModel" :isCommit="isCommit" @submit="dialogSubmit">
		</dialog-form>
	</div>
</template>

<script>
// import tableBase from '../components/tableBase.vue';
import dialogForm from '../components/dialogForm.vue';
// import jobBind from './jobBind.vue';
// import positionStation from './positionStation.vue';
import position from '../positionManagement/position.vue';
import jobs from '../positionManagement/jobs.vue';
export default {
	props: { pageName: {} },
	components: {
		// tableBase,
		dialogForm,
		// jobBind,
		// positionStation,
		position,
		jobs
	},
	data() {
		return {
			activeName: '1',
			loading: false,
			isCommit: false,
			searchForm: {
				type: '2', // 2：岗位
				name: '',
				pageNo: 1,
				pageSize: 20
			},
			pageSize: 20,
			total: 0,
			tableData: [],
			jobList: [],
			tableConfig: [
				{
					label: '岗位',
					prop: 'jobOrOfficeName'
				},
				{
					label: '修改人',
					prop: 'name'
				},
				{
					label: '修改时间',
					prop: 'updateTime'
				}
			],
			formTitle: '',
			// dialogFormWidth:"500",
			formConfig: [
				{
					span: 24,
					label: '岗位名称',
					prop: 'name',
					type: 'text',
					placeholder: '请输入岗位名称',
					rules: { required: true, message: '请输入岗位名称', trigger: 'blur' }
				}
			],
			formModel: {
				id: '',
				name: '',
				type: '2'
			}
		};
	},
	mounted() {
		// this.getJobList();
		this.getList();
		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: '1', key: 'JobPosition' },
			{ name: '2', key: 'Jobs' }
		]);
	},
	methods: {
		query() {
			this.searchForm.pageNo = 1;
			this.getList();
		},
		tabClick(tab) {
			this.searchForm.name = '';

			if (tab.name == '2') {
				this.getList();
			}
		},
		// getJobList () {
		//   this.$axios.post('/v1/org/queryStaffJob/list', {
		//     "request": {},
		//     "sign": ""
		//   }).then(res => {
		//     this.jobList = res;

		//   })
		// },
		getList() {
			this.loading = true;
			this.$axios
				.post('/v1/org/queryOfficeJob/list', {
					request: this.searchForm,
					sign: ''
				})
				.then((res) => {
					this.total = res.totalNum;
					this.tableData = res.list;

					this.loading = false;
				});
		},
		// 添加
		handleAdd() {
			if (this.activeName == '2') {
				this.formModel = {
					id: '',
					name: '',
					type: '2'
				};
				this.formTitle = '添加岗位';
				this.$refs.dialogForm.resetForm();
				this.$refs.dialogForm.showDialog(true);
			}
			if (this.activeName == '3') {
				this.$refs.bind.handleAdd();
			}
			if (this.activeName == '1') {
				this.$refs.station.handleAdd();
			}
		},
		handleEdit(row) {
			this.formTitle = '编辑岗位';
			this.$refs.dialogForm.resetForm();
			this.$refs.dialogForm.showDialog(true);
			this.formModel = {
				id: row.id,
				name: row.jobOrOfficeName,
				type: '2'
			};
		},
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/org/deleteOfficeJob', {
						request: { id: Number(row.id), type: '2' },
						sign: ''
					})
					.then((res) => {
						if (res && res.code == 0) {
							this.$message.success('删除成功!');
							this.getList();
						} else {
							// this.$message.error(res.message)
						}
					});
			});
		},
		dialogSubmit(data) {
			this.isCommit = true;
			this.$axios
				.post('/v1/org/saveOfficeJob', { request: data, sign: '' })
				.then((res) => {
					const tooltip = data.id ? '编辑成功！' : '添加成功';

					if (res && res.code == 0) {
						this.isCommit = false;
						this.$message.success(tooltip);
						this.searchForm.pageNo = 1;
						this.searchForm.name = '';
						this.getList();
						this.$refs.dialogForm.showDialog(false);
						this.$refs.bind.getJobList(data.type);
					} else {
						this.isCommit = false;
					}
				})
				.catch(() => {
					this.isCommit = false;
				});
			// this.$refs.dialogForm.showDialog(false);
		},
		currentPageChange(data) {
			this.searchForm.pageNo = data;
			this.getList();
		},
		handleSizeChange(data) {
			this.searchForm.pageSize = data;
			this.getList();
		}
	}
};
</script>

<style lang="scss" scoped>
.search_box {
	margin: 10px 0;
}
.tabs_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	// padding-right: 32px;
}
.tabBtn {
	position: absolute;
	right: 20px;
	top: 20px;
}
.add_btn {
	height: 35px;
	width: 80px;
	line-height: 10px;
	// font-size: 14px;
}
</style>

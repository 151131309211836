<!--  -->
<template>
	<div class="home">
		<el-table :data="tableData" border class="tableBackground" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
			<!-- :reserve-selection="true" -->
			<el-table-column type="selection" width="55" v-if="operateShow.selection"></el-table-column>
			<el-table-column type="index" :index="typeIndex" width="60" label="序号" sortable align="center"></el-table-column>
			<el-table-column
				v-for="(item, index) in tableConfig"
				:key="index"
				:prop="item.prop"
				:label="item.label"
				show-overflow-tooltip
				:width="item.width ? item.width : ''"
				align="center"
			>
			</el-table-column>
			<!-- :width="gridEditWidth?gridEditWidth:200" -->
			<el-table-column label="操作" v-if="operateShow.show" align="center">
				<template slot-scope="scope">
					<el-button
						size="mini"
						type="text"
						v-if="operateShow.edit && permissionControlBtns(pageName, 'Edit')"
						@click="handleEdit(scope.row)"
						>编辑
					</el-button>
					<el-button
						size="mini"
						type="text"
						style="color: #f56c6c"
						v-if="operateShow.del && permissionControlBtns(pageName, 'Delete')"
						@click="handleDel(scope.row)"
						>删除</el-button
					>
				</template>
			</el-table-column>
		</el-table>
		<div class="crud-pagination">
			<!--如果不是异步请求展示数据，需要隐藏分页-->
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				layout="total, sizes, prev, pager, next, jumper"
				:total="dataTotal"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	props: {
		tableData: {
			type: Array
		},
		tableConfig: {
			type: Array
		},
		currentPage: {
			type: Number,
			default() {
				1;
			}
		},
		dataTotal: {
			type: Number
		},
		loading: {
			type: Boolean,
			default: false
		},
		operateShow: {
			type: Object,
			default: () => ({
				show: true,
				edit: true,
				del: true,
				selection: false
			})
		},
		subPageName: {}
	},
	computed: {
		pageName() {
			if (this.subPageName) {
				return this.subPageName;
			}
			return this.$parent?.pageName;
		}
	},
	watch: {
		/* 实现表单数据的绑定，实时接收父组件的数据变化*/
		currentPage() {
			this.pageNo = this.currentPage;
		}
	},
	data() {
		return {
			pageNo: 1,
			currentPageSize: 20

			// currentPage: 1,
		};
	},
	mounted() {},
	methods: {
		typeIndex(index) {
			return index + (this.pageNo - 1) * this.currentPageSize + 1;
		},
		handleEdit(row) {
			this.$emit('edit', row);
		},
		handleDel(row) {
			this.$emit('remove', row);
		},
		handleCurrentChange(val) {
			this.pageNo = val;
			this.$emit('pageNumberChange', val);
		},
		handleSizeChange(val) {
			this.currentPageSize = val;
			this.$emit('pageSizeChange', val);
		},
		handleSelectionChange(val) {
			this.$emit('handleDelMore', val);
		},
		delAllSuccess() {
			this.selectList = [];
		}
	}
};
</script>
<style lang="scss">
.el-tooltip__popper {
	max-width: 35%;
}
</style>
<style lang="scss" scoped>
.crud-pagination {
	text-align: right;
	margin-top: 10px;
}
</style>

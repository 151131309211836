<!--  职位查询  -->
<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			@rowClick="rowClick"
			:custom="{ local: true }"
			:activeTab="activeTab"
		/>
		<!-- 导入按钮的弹窗 -->
		<Upload :visible.sync="dialogImportVisible" :config="uploadConfig" />
		<addJob :visible="dialogAddVisible" :formType="this.formType" :jobId="jobId" :orgId="orgId" @close="dialogAddVisible = false" />
	</div>
</template>

<script>
/* 组织职能 */
import baseTable from '@/views/components/components-base-table';
import Upload from '@/components/Upload/index.vue';
import { downloadByCreateA } from '@/utils/util';
import addJob from './addJob';
// import { queryItem } from './pageConfig.js';
// import moment from 'moment';
export default {
	name: 'organizationalFunctions',
	components: {
		baseTable,
		Upload,
		addJob
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	computed: {},
	data() {
		return {
			tableCols: [
				{
					label: '职级',
					key: 'officeLevel'
				},
				{
					label: '职级代码',
					key: 'officeCode'
				},
				{
					label: '职族',
					key: 'familyName'
				},
				{
					label: '职类',
					key: 'className'
				},
				{
					label: '职类子类',
					key: 'subclassName'
				},
				{
					label: '组织',
					key: 'orgName'
				},
				{
					label: '职位',
					key: 'officeName'
				},
				{
					label: '岗位',
					key: 'jobName'
				},
				// {
				// 	label: '职责说明',
				// 	key: 'dutyIllustrate'
				// },
				this.permissionControlBtns(this.pageName, 'JobBookView') && {
					label: '岗位说明书',
					key: 'cz',
					render: (row) => (
						<div>
							<el-button type="text" onclick={(e) => this.handleView(row, e)}>
								详情
							</el-button>
						</div>
					)
				}
			].filter((v) => v),
			queryItem: [
				{ label: '职族', key: 'familyName', type: 'input', span: 6 },
				{ label: '职类', key: 'className', type: 'input', span: 6 },
				{ label: '职级', key: 'officeLevel', type: 'input', span: 6 },
				{ label: '职位', key: 'officeName', type: 'input', span: 6 },
				{ label: '岗位', key: 'jobName', type: 'input', span: 6 },
				{
					label: '组织',
					key: 'orgId',
					type: 'tree',
					async: () => this.$axios.post('/v1/org/list', { request: {} }),
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					span: 6,
					filter: true
				}
			],

			otherBtns: [
				this.permissionControlBtns(this.pageName, 'Export') && {
					label: '导出',
					icon: 'el-icon-download',
					style: { float: 'right' },
					click: (_, searchForm) => {
						const outStr = encodeURIComponent(JSON.stringify(searchForm));
						const url = `/v1/profession/system/export?params=${outStr}`;

						downloadByCreateA(url);
					}
				},
				this.permissionControlBtns(this.pageName, 'Import') && {
					label: '导入',
					icon: 'el-icon-upload',
					style: { float: 'right' },
					click: () => {
						this.$confirm(
							'导入会覆盖掉之前导入的数据，导致已关联的员工职位相关信息丢失，员工职级相关信息需要重新录入，您确定要导入?',
							'导入提示',
							{
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								center: true,
								type: 'warning'
							}
						).then(() => {
							this.dialogImportVisible = true;
						});
					}
				}
			].filter((v) => v),
			formModel: {},
			dialogItems: [
				{
					label: '组织名称',
					key: 'orgId',
					type: 'tree',
					options: this.$store.state.setData?.options?.orgId || [],
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					filter: true,
					rules: { required: true, message: '请选择', trigger: 'change' }
				},
				{
					label: '责任岗位',
					key: 'jobId',
					type: 'selectSearch',
					firstRequest: true,
					async: (query) =>
						this.$axios
							.post('/v1/profession/job/list', { request: { jobName: query || '', pageNo: 1, pageSize: 40 } })
							.then((v = {}) => v.list.map(({ jobName, id }) => ({ dictId: id, dictName: jobName }))),
					change: async (formModel) => {
						const { id } = formModel;
						const { job = {} } = await this.getDetailInfo({ id });

						if (job.jobId) {
							this.$set(formModel, 'staffId', job.jobId);
						}
						if (job.jobName) {
							this.$set(formModel, 'staffName', job.jobName);
						}
					}
				},
				{
					label: '负责人',
					key: 'staffId',
					type: 'selectSearch',
					firstRequest: true,
					async: (query) =>
						this.$axios
							.post('/v1/common/findStaffList', { request: { staffName: query || '', pageNo: 1, pageSize: 40 } })
							.then((v = {}) => v.map(({ staffName, staffId }) => ({ dictId: staffId, dictName: staffName }))),
					change: async (formModel) => {
						const { staffId } = formModel;
						const { job = {} } = await this.getDetailInfo({ staffId });

						if (job.jobId) {
							this.$set(formModel, 'jobId', job.jobId);
						}
						if (job.jobName) {
							this.$set(formModel, 'jobName', job.jobName);
						}
					},
					rules: { required: true, message: '请选择', trigger: 'change' }
				}
			],
			dialogTitle: '添加',
			formType: 'edit',
			dataList: [{}],
			// 导入配置
			uploadConfig: {
				dictId: 'professionSystemTemplate',
				groupId: 'importTemplate',
				importUrl: '/v1/profession/system/import',
				successMsg: '导入成功',
				callBack: () => {
					this.$refs.baseTable?.handleSearch();
					this.dialogImportVisible = false;
				}
			},
			dialogImportVisible: false,
			dialogAddVisible: false,
			jobId: '',
			orgId: ''
		};
	},
	created() {},
	mounted() {},
	methods: {
		getTableData(params, callback) {
			callback(this.$axios.post('/v1/profession/system/list', params));
		},
		// 详情
		handleView(row, e) {
			e.stopPropagation();
			this.formType = 'view';
			this.jobId = row.jobId;
			this.orgId = row.orgId;
			this.dialogAddVisible = true;
			// this.dialogTitle = '编辑';
			// this.formModel = { ...row };
		},
		// 删除
		handleDel(row, e) {
			e.stopPropagation();
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/org/delOrgLeader', {
						request: { id: row.id },
						sign: ''
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.$refs.baseTable?.handleSearch();
						}
					});
			});
		},
		// 提交
		submitForm(form) {
			form.validate((valid) => {
				if (valid) {
					this.$axios
						.post('/v1/org/saveOrgLeader', { request: this.formModel })
						.then((res) => {
							if (res.code) {
								return;
							}
							this.$message.success('保存成功');
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {});
				}
			});
		},
		// 点击table行
		rowClick(e) {
			console.log(e, 'rowClick');
			// this.formType = 'view';
			// this.dialogTitle = '详情';
			// // this.viewData = [{}];
		},
		// 根据岗位查询员工或根据员工查询岗位
		getDetailInfo(data) {
			return this.$axios
				.post('/v1/org/findJobStaffInfo', { request: data })
				.then((res) => {
					if (res.code) {
						return {};
					}
					return res;
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	// padding: 20px;
	.addFunctions {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 30px 0;
		justify-content: center;
	}
}
</style>
<style lang="scss">
.noMarginBottom {
	.el-form-item {
		margin-bottom: 0;
	}
}
</style>

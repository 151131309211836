<template>

  <el-dialog :title="title"
              top="30vh"
              center
             :visible.sync="dialogVisible"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             class="form_dialog_style"
             width="550px">
    <el-form :model="formModel"
             :rules="rules"
             ref="configForm"
             label-width="110px">
      <el-form-item label="职族名称"
                    prop="name">
        <el-input v-model="formModel.name"
                  placeholder="请输入职族名称"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                :loading="isCommit"
                 @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'base-dialog-form',
  props: [
    'title',
    // 'width',
    'visible',
    // 'config',
    'formData',
    'codeList',
    'isCommit'
  ],
  data () {
    return {
      formModel: {},
      dialogVisible: false,
      dialogTitle: '',
      limitArray: [],
      // isCommit:false,
      rules: {
        name: [{ required: true, message: '请输入职级名称', trigger: 'blur' }]
      }
    };
  },
  mounted () {
    // 将组件上的属性赋值给当前组件内变量，因为props只能单向绑定,还需要监听属性值变化进行父子组件间交互
    this.formModel = this.formData;
    this.dialogVisible = this.visible;
    this.dialogTitle = this.title;
    for (let i = 1; i <= 100; i++) {
      this.limitArray.push({ value: String(i) });
    }
  },
  methods: {
    // 提交表单数据
    submitForm () {
      this.$refs.configForm.validate((valid) => {
        if (valid) {
          // 让父组件接收到响应数据
          this.$emit('submit', this.formModel);
          // 关闭模态框
          // this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    // 重置表单状态
    resetForm () {
      if (this.$refs.configForm) {
        this.$refs.configForm.resetFields();
      }
    },
    // 展示模态框
    showDialog (val) {
      this.dialogVisible = val;
    }
  },
  watch: {
    /* 实现表单数据的绑定，实时接收父组件的数据变化*/
    formData () {
      this.formModel = this.formData;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-input {
  width: 100% !important;
}

.el-select {
  width: 100% !important;
}
</style>

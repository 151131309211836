<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			@rowClick="rowClick"
			showOrder
			:activeTab="activeTab"
		/>
	</div>
</template>

<script>
/* 组织职能 */
import baseTable from '@/views/components/components-base-table';
// import { setUrlParams } from '@/utils/util';
import { getApprovalTypeOnce } from '@/api/requestOnce';
// import { queryItem } from './pageConfig.js';
// import moment from 'moment';
export default {
	name: 'jobApprovalQuery',
	components: {
		baseTable
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	computed: {
		applyTypeList() {
			return this.$store.state.approval.applyTypeList;
		},
		applyName() {
			let name = '';

			switch (this.activeTab) {
				case '15':
					name = '职级调整';
					break;
				case '16':
					name = '职族调整';
					break;
				case '18':
					name = '职位调整';
					break;
				case '19':
					name = '岗位调整';
					break;

				default:
					break;
			}
			return name;
		},
		otherBtns() {
			const data = this.applyTypeList.find(({ subType }) => this.activeTab === subType.toString());
			const childs = data?.child || [];

			return [
				this.permissionControlBtns(this.pageName, 'Add') && {
					style: { float: 'right' },
					render: () => (
						<el-popover placement="bottom-start" trigger="click" width="100">
							<div>
								<el-row>
									{childs.map((val, inx) => (
										<el-col key={inx} span={24} style="cursor:pointer;padding:4px 0;text-align:center">
											<div onclick={() => this.approvalSet({ ...val, approvalId: data.approvalId, subType: data.subType })}>
												{val.childApprovalName}
											</div>
										</el-col>
									))}
								</el-row>
							</div>
							<el-button slot="reference" size="small" type="primary" icon="el-icon-s-order" style="float: right">
								申请{data.approvalName}
							</el-button>
						</el-popover>
					)
				}
			].filter((v) => v);
		}
	},
	data() {
		return {
			tableCols: [
				{
					label: '发起人',
					key: 'applyUserName'
				},
				{
					label: '发起人部门',
					key: 'approvalDepartmentName'
				},
				{
					label: '调整内容',
					key: 'adjustContent'
				},
				{
					label: '当前审批人',
					key: 'approvalStaffName'
				},
				{
					label: '审批发起时间',
					key: 'approvalTime'
				},
				{
					label: '操作时间',
					key: 'applyTime'
				},
				{
					label: '审批状态',
					key: 'approvalStatusName',
					render: (row) => <div style={`color:${this.returnStatusColor(row.approvalStatus)}`}>{row.approvalStatusName}</div>
				}
			],
			queryItem: [
				{ label: '发起人', key: 'applyUserName', type: 'input', span: 6 },
				{
					label: '发起部门',
					key: 'approvalDepartmentId',
					type: 'tree',
					options: this.$store.state.setData.depsList,
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					filter: true,
					span: 6
				},
				{
					label: '审批状态',
					key: 'approvalStatus',
					type: 'select',
					options: this.$store.state.app.dict.filter((item) => item.groupId == 'approvalStatus'),
					span: 6
				},
				{ label: '审批发起日期', key: 'applyTime', type: 'date', span: 6 },
				{ label: '操作时间', key: 'approvalTime', type: 'date', span: 6 }
			],

			formModel: {}
		};
	},
	created() {},
	mounted() {
		getApprovalTypeOnce();
	},
	methods: {
		getTableData(params, callback) {
			params.request.subType = this.activeTab;
			callback(this.$axios.post('/v1/approval/list/get', params));
		},
		returnStatusColor(status) {
			// eslint-disable-next-line default-case
			switch (status) {
				case 1:
					return '#64C940';
				case 2:
					return '#FF604D';
				case 0:
					return '#409EFF';
			}
		},
		// 点击table行
		rowClick(row) {
			this.$router.push({
				path: '/audit_info',
				query: {
					id: row.id
				}
			});
		},
		// 根据岗位查询员工或根据员工查询岗位
		getDetailInfo(data) {
			return this.$axios
				.post('/v1/org/findJobStaffInfo', { request: data })
				.then((res) => {
					if (res.code) {
						return {};
					}
					return res;
				})
				.catch(() => {});
		},
		approvalSet({ subType, approvalId, childSubType, childApprovalName }) {
			this.$router.push({
				path: '/staff/approval_apply',
				query: {
					mode: '申请审核',
					subType,
					approvalId,
					childSubType,
					childApprovalName
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	// padding: 20px;
	.addFunctions {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 30px 0;
		justify-content: center;
	}
}
</style>
<style lang="scss">
.noMarginBottom {
	.el-form-item {
		margin-bottom: 0;
	}
}
</style>

import { render, staticRenderFns } from "./rankForm.vue?vue&type=template&id=755a50f4&scoped=true&"
import script from "./rankForm.vue?vue&type=script&lang=js&"
export * from "./rankForm.vue?vue&type=script&lang=js&"
import style0 from "./rankForm.vue?vue&type=style&index=0&id=755a50f4&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755a50f4",
  null
  
)

export default component.exports
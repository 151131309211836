import { post } from './api';
import store from '@/store';

export const getApprovalTypeOnce = (params) => {
	const { applyTypeList } = store.state.approval;
	if (applyTypeList?.length) {
		return Promise.resolve(applyTypeList);
	}
	return post('/v1/approval/info/get', { request: params || {} }).then((res) => {
		store.commit('SET_APPLY_TYPE', res);
		return res;
	});
};

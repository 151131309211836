<template>
	<!-- 职位职级表 -->
	<div class="rank-table">
		<!-- table -->
		<baseTable ref="baseTable" :activeTab="activeTab" @request="getTableData" :queryItem="queryItem" :columns="tableConfig" custom />
	</div>
</template>

<script>
import baseTable from '@/views/components/components-base-table';
import { transfromData } from '@/utils/util';

export default {
	props: {
		activeName: {
			type: String
		},
		activeTab: {
			type: String
		}
	},
	components: { baseTable },
	watch: {
		activeName(val) {
			if (val === '3') {
				this.getListToTable();
			}
		}
	},
	data() {
		return {
			// activeName: "2",
			operateShow: {
				show: false
			},
			loading: false,
			searchForm: {
				name: '',
				updateTimeBefore: '',
				updateTimeEnd: '',
				pageNo: 1,
				pageSize: 20
			},
			pageSize: 20,
			total: 0,

			tableData: [],
			tableConfig: [],
			queryItem: [
				{
					label: '组织',
					key: 'orgIds',
					type: 'tree',
					async: () => this.$axios.post('/v1/org/list', { request: {} }),
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					span: 6,
					filter: true,
					multiple: true
				}
			]
		};
	},
	mounted() {
		// this.getListToTable();
	},
	methods: {
		getTableData(params, callback) {
			if (params.request?.orgIds) {
				params.request.orgIds = params.request.orgIds.split(',');
			}
			callback(
				this.$axios.post('/v1/profession/system/listToTable', params).then((res) => {
					this.tableConfig = transfromData(res.tableConfig, {
						children: ['child', 'children'],
						data: ['value', 'key'],
						render: (row, inx, item) => {
							const val = row[`jobName${item.key}`] || [];

							if (!val.length) {
								return <span>{row[item.key]}</span>;
							}
							return (
								<el-popover placement="top-start" title="岗位" width="200" trigger="hover">
									<span slot="reference">{row[item.key]}</span>
									<ul>
										{val.map((v) => (
											<li>{v}</li>
										))}
									</ul>
								</el-popover>
							);
						},
						renderBy: res.jobNameKeys
					});
					return { list: res.tableData };
				})
			);
		}
	}
};
</script>
<style lang="scss">
.rank-table {
	.el-table thead.is-group th {
		background-color: #f0f8ff;
	}
}
</style>
<style lang="scss" scoped>
.search_box {
	margin: 10px 0;
}
</style>

<!--  职位  -->
<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			@rowClick="rowClick"
			showOrder
		/>
		<!--  -->
		<el-dialog
			:title="dialogTitle"
			top="30vh"
			width="900px"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				:formItems="dialogItems"
				@cancel="dialogVisible = false"
				@submit="submitForm"
				:defaultData="formModel"
				style="margin: 20px 60px"
				:type="'edit'"
			/>
		</el-dialog>
	</div>
</template>

<script>
/* 职位 */
import baseTable from '@/views/components/components-base-table';
import formItem from '@/views/components/components-form-item/index.vue';
// import { queryItem } from './pageConfig.js';
// import moment from 'moment';
export default {
	name: 'organizationalFunctions',
	components: {
		baseTable,
		formItem
	},

	props: { pageName: {} },
	computed: {},
	data() {
		return {
			tableCols: [
				{
					label: '组织',
					key: 'orgName'
				},
				{
					label: '职位',
					key: 'officeName'
				},
				{
					label: '操作',
					key: 'cz',
					render: (row) => (
						<div>
							{this.permissionControlBtns(this.pageName, 'Edit') && (
								<el-button type="text" onclick={(e) => this.handleEdit(row, e)}>
									编辑
								</el-button>
							)}
							{this.permissionControlBtns(this.pageName, 'Delete') && (
								<el-button
									type="text"
									style={'color:rgb(245, 108, 108); '}
									// disabled={this.getDelDisabled(row, inx)}
									onclick={(e) => this.handleDel(row, e)}
								>
									删除
								</el-button>
							)}
						</div>
					)
				}
			],
			queryItem: [
				{
					label: '组织',
					key: 'orgId',
					type: 'tree',
					async: () => this.$axios.post('/v1/org/list', { request: {} }),
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					span: 6,
					filter: true
				},
				{ label: '职位', key: 'officeName', type: 'input', span: 6 }
			],

			otherBtns: [
				this.permissionControlBtns(this.pageName, 'Add') && {
					label: '新增职位',
					icon: 'el-icon-plus',
					style: { float: 'right' },
					click: () => {
						this.dialogTitle = '新增职位';
						this.formModel = {};
						this.dialogVisible = true;
					}
				}
			].filter((v) => v),
			dialogVisible: false,
			formModel: {},
			dialogItems: [
				{ label: '职位名称', key: 'officeName', type: 'input', span: 12, rules: { required: true, message: '请选择', trigger: 'change' } },
				{
					label: '职族/职类',
					key: 'familyId',
					type: 'cascader',
					async: () => this.$axios.post('/v1/profession/office/linkage', { request: {} }),
					props: {
						disabled: 'disable',
						// checkStrictly: true,
						expandTrigger: 'hover'
					},
					rules: { required: true, message: '请选择', trigger: 'change' },
					span: 12,
					change: (formData, _, item) => {
						const familyIdOpts = this.$store.state.setData.options?.familyId;
						const manOpt = familyIdOpts.find(({ label }) => label === '管理族');

						if (formData?.familyId?.length && formData?.officeLevel) {
							this.getOfficeCode(formData);
						}
						if (formData.familyId?.includes(manOpt?.value)) {
							item.splice(2, 0, {
								label: '管理层级',
								key: 'manageLevel',
								type: 'select',
								groupId: 'rankName',
								rules: { required: true, message: '请选择', trigger: 'change' },
								span: 12
							});
							return;
						}
						if (item.find(({ key }) => key === 'manageLevel')) {
							item.splice(2, 1);
						}
					}
				},
				// {
				// 	label: '管理层级',
				// 	key: 'manageLevel',
				// 	type: 'select',
				// 	groupId: 'rankName',
				// 	rules: { required: true, message: '请选择', trigger: 'change' },
				// 	span: 12
				// },
				{
					label: '组织',
					key: 'orgId',
					type: 'tree',
					options: this.$store.state.setData?.options?.orgId || [],
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					filter: true,
					rules: { required: true, message: '请选择', trigger: 'change' },
					span: 12
				},
				{
					label: '职级',
					key: 'officeLevel',
					// type: 'inputNumber',
					// rules: { required: true, message: '请输入', trigger: 'change' },
					// min: 0,
					// max: 20,
					// step: 1,
					// span: 12,
					// precision: 0,
					change: (formData) => {
						if (formData?.familyId?.length && formData?.officeLevel) {
							this.getOfficeCode(formData);
						}
					},
					type: 'select',
					async: () =>
						this.$axios
							.get('/v1/common/getCompanyLevel', { request: {} })
							.then((res) => res.map((num) => ({ dictId: num, dictName: num }))),
					rules: { required: true, message: '请选择', trigger: 'change' },
					span: 12
				},
				{
					label: '职级代码',
					key: 'officeCode',
					type: 'input',
					rules: { required: true, message: '请输入', trigger: 'change' },
					span: 12,
					change: (formData) => {
						console.log('change');
						const { officeCode, officeName, orgId } = formData;

						if (officeCode && this.oldOfficeCode && this.oldOfficeCode !== officeCode && officeName && orgId) {
							this.$confirm(
								'该“职级、职族“下，职级代码已存在，此修改会同步修改该”职级、职族“下其他职位的职级代码，确定要进行修改吗？',
								{
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}
							)
								.then(() => {})
								.catch(() => {
									this.$set(formData, 'officeCode', this.oldOfficeCode);
								});
						}
					}
				}
			],
			dialogTitle: '添加',
			formType: 'edit',
			oldOfficeCode: ''
		};
	},
	created() {},
	mounted() {},
	methods: {
		getTableData(params, callback) {
			callback(this.$axios.post('/v1/profession/office/list', params));
		},
		// 编辑
		handleEdit(row, e) {
			e.stopPropagation();
			this.formType = 'edit';
			this.dialogTitle = '编辑';
			const params = { ...row };

			if (params.familyId) {
				const { familyId, classId, subclassId } = params;

				params.familyId = [familyId?.toString(), classId?.toString(), subclassId?.toString()]?.filter((v) => v !== '0');
			}
			if (params.officeCode) {
				this.oldOfficeCode = params.officeCode;
			}
			this.formModel = params;
			this.dialogVisible = true;
		},
		// 删除
		handleDel(row, e) {
			e.stopPropagation();
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/profession/office/del', {
						request: { id: row.id }, // { "id": [row.id * 1] },
						sign: ''
					})
					.then((res) => {
						if (res && res.code == 0) {
							this.$message.success('删除成功!');
							this.$refs.baseTable?.handleSearch();
						} else {
							// this.$message.error(res.message)
						}
					});
			});
		},
		// 提交
		submitForm(form) {
			form.validate((valid) => {
				if (valid) {
					const params = { ...this.formModel, oldOfficeCode: this.oldOfficeCode };

					if (Array.isArray(params.familyId)) {
						const arr = params.familyId;

						params.familyId = arr[0];
						params.classId = arr[1];
						params.subclassId = arr[2];
					}
					this.$axios
						.post('/v1/profession/office/save', { request: params })
						.then((res) => {
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success('保存成功');
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		},
		// 点击table行
		rowClick(e) {
			console.log(e, 'rowClick');
			// this.formType = 'view';
			// this.dialogTitle = '详情';
			// // this.viewData = [{}];
			// this.dialogVisible = true;
		},
		// 获取 职级代码
		getOfficeCode(e) {
			this.$axios
				.post('/v1/profession/office/detail', {
					request: { familyId: e.familyId?.[0], officeLevel: e.officeLevel }
				})
				.then((res) => {
					if (res.code) {
						return {};
					}
					this.oldOfficeCode = res?.officeCode;
					this.$set(e, 'officeCode', res?.officeCode);
				})
				.catch(() => {});
		},
		// 根据岗位查询员工或根据员工查询岗位
		getDetailInfo(data) {
			return this.$axios
				.post('/v1/org/findJobStaffInfo', { request: data })
				.then((res) => {
					if (res.code) {
						return {};
					}
					return res;
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	// padding: 20px;
	.addFunctions {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 30px 0;
		justify-content: center;
	}
}
</style>
<style lang="scss">
.noMarginBottom {
	.el-form-item {
		margin-bottom: 0;
	}
}
</style>

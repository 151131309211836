<template>
	<!-- 职族配置 -->
	<div>
		<!-- 搜索框 -->
		<div class="search_box">
			<el-form :inline="true" :model="searchForm" class="demo-form-inline">
				<el-form-item class="formLabel" label="职族" style="margin-right: 10px">
					<el-input v-model="searchForm.name" size="small" placeholder="请输入职族名称"></el-input>
				</el-form-item>
				<!-- <el-form-item class="formLabel" label="修改时间">
					<el-col :span="11">
						<el-date-picker
							type="date"
							size="small"
							value-format="yyyy-MM-dd HH-mm-ss"
							placeholder="选择开始时间"
							v-model="searchForm.updateTimeBefore"
							style="width: 100%"
						></el-date-picker>
					</el-col>
					<el-col class="line" style="text-align: center" :span="1">-</el-col>
					<el-col :span="11">
						<el-date-picker
							type="date"
							size="small"
							value-format="yyyy-MM-dd"
							placeholder="选择结束时间"
							v-model="searchForm.updateTimeEnd"
							style="width: 100%"
						></el-date-picker>
					</el-col>
				</el-form-item> -->
				<el-button type="primary" @click="query" size="small" icon="el-icon-search" style="margin: 5px 0 0 10px">查询</el-button>
				<el-button
					size="small"
					type="primary"
					icon="el-icon-plus"
					style="float: right; margin: 5px 0 0 10px"
					v-show="activeName == 'three'"
					@click="handleAdd"
					v-if="permissionControlBtns(pageName, 'Add')"
					>添加</el-button
				>
			</el-form>
		</div>
		<!-- table -->
		<table-base
			:tableData="tableData"
			:tableConfig="tableConfig"
			:dataTotal="total"
			:loading="loading"
			:currentPage="searchForm.pageNo"
			@pageSizeChange="handleSizeChange"
			@pageNumberChange="currentPageChange"
			@edit="handleEdit"
			@remove="handleDel"
		></table-base>
		<!-- add/edie  :config="formConfig"-->
		<dialog-form :title="formTitle" ref="dialogForm" :form-data="formModel" :isCommit="isCommit" @submit="dialogSubmit" :codeList="codeList">
		</dialog-form>
	</div>
</template>

<script>
import tableBase from '../components/tableBase.vue';
import dialogForm from './rankForm.vue';
export default {
	props: {
		activeName: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	components: {
		tableBase,
		dialogForm
	},
	watch: {
		activeName(val) {
			if (val === 'three') {
				this.getList();
			}
		}
	},
	data() {
		return {
			// activeName: "2",
			loading: false,
			searchForm: {
				name: '',
				updateTimeBefore: '',
				updateTimeEnd: '',
				pageNo: 1,
				pageSize: 20
			},
			pageSize: 20,
			total: 0,

			tableData: [],
			codeList: [],
			isCommit: false,
			tableConfig: [
				{
					label: '职族',
					prop: 'name'
				}
				// {
				//   label: "职级代码",
				//   prop: "codeRange"
				// },

				// {
				//   label: "职级",
				//   prop: "levelRange"
				// },
				// {
				// 	label: '修改人',
				// 	prop: 'userName'
				// },
				// {
				// 	label: '修改时间',
				// 	prop: 'updateTime'
				// }
			],
			formTitle: '',
			formModel: {}
		};
	},
	mounted() {
		this.getList();
	},
	methods: {
		query() {
			this.searchForm.pageNo = 1;
			this.getList();
		},
		getList() {
			this.loading = true;

			this.$axios
				.post('/v1/profession/family/list', {
					request: {
						name: this.searchForm.name,
						updateTimeBefore: this.searchForm.updateTimeBefore || '',
						// eslint-disable-next-line no-useless-concat
						updateTimeEnd: this.searchForm.updateTimeEnd ? `${this.searchForm.updateTimeEnd} ` + '23:59:59' : '',
						pageNo: this.searchForm.pageNo,
						pageSize: this.searchForm.pageSize
					},
					sign: ''
				})
				.then((res) => {
					this.total = res.totalNum;
					this.tableData = res.list;

					this.loading = false;
				});
		},
		handleAdd() {
			this.formTitle = '添加职族';
			this.formModel = {
				id: '',
				codeName: '',
				name: '',
				codeMinLevel: '',
				codeMaxLevel: '',
				rankMinLevel: '',
				rankMaxLevel: ''
			};
			this.$refs.dialogForm.resetForm();
			this.$refs.dialogForm.showDialog(true);
		},
		handleEdit(row) {
			this.$refs.dialogForm.resetForm();
			//   let rankArray = row.rank.split('-');
			this.formTitle = '编辑职族';
			// eslint-disable-next-line no-unused-expressions
			this.formModel = {
				id: row.id,
				codeName: row.codeName,
				name: row.name,
				codeMinLevel: row.codeMinLevel,
				codeMaxLevel: row.codeMaxLevel,
				rankMinLevel: row.rankMinLevel,
				rankMaxLevel: row.rankMaxLevel
			};
			this.$refs.dialogForm.showDialog(true);
		},
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/profession/family/del', {
						request: { id: Number(row.id) },
						sign: ''
					})
					.then((res) => {
						if (res && res.code == 0) {
							this.$message.success('删除成功!');
							this.getList();
						} else {
							// this.$message.error(res.message)
						}
					});
			});
		},
		dialogSubmit(data) {
			this.isCommit = true;
			this.$axios.post('/v1/profession/family/save', { request: data, sign: '' }).then((res) => {
				const tooltip = data.id ? '编辑成功！' : '添加成功';

				if (res && res.code == 0) {
					this.isCommit = false;
					this.$message.success(tooltip);
					this.searchForm.pageNo = 1;
					this.searchForm.name = '';
					this.searchForm.updateTimeBefore = '';
					this.searchForm.updateTimeEnd = '';
					this.getList();
					this.$refs.dialogForm.showDialog(false);
				} else {
					this.isCommit = false;
				}
			});
			// this.$refs.dialogForm.showDialog(false);
		},
		currentPageChange(data) {
			this.searchForm.pageNo = data;
			this.getList();
		},
		handleSizeChange(data) {
			this.searchForm.pageSize = data;
			this.getList();
		}
	}
};
</script>
<style lang="scss" scoped>
.search_box {
	margin: 10px 0;
}
</style>

<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="职位职级表" name="1" v-if="permissionControl('JobRankList')">
				<rankTable ref="positionView1" :activeTab="`${activeTab}_${activeName}`" v-if="activeName === '1'" pageName="JobRankList" />
			</el-tab-pane>
			<el-tab-pane label="职位查询" name="2" v-if="permissionControl('JobCheck')">
				<query ref="positionView2" :activeTab="`${activeTab}_${activeName}`" v-if="activeName === '2'" pageName="JobCheck" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import query from './positionManagement/query.vue';
import rankTable from './rankSystem/rankTable.vue';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'organizationalList',
	components: { query, rankTable },
	props: { activeTab: { type: String } },
	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('positionViewData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'positionViewData',
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`positionView${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>

<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="职级调整" name="15" v-if="permissionControl('RankAdjustment')">
				<jobApproval ref="positionApproval1" :activeTab="activeName" v-if="activeName === '15'" pageName="RankAdjustment" />
			</el-tab-pane>
			<el-tab-pane label="职族调整" name="16" v-if="permissionControl('JobFamilyAdjustment')">
				<jobApproval ref="positionApproval2" :activeTab="activeName" v-if="activeName === '16'" pageName="JobFamilyAdjustment" />
			</el-tab-pane>
			<!-- <el-tab-pane label="职类调整" name="17" v-if="permissionControl('PositionAdjustment')">
				<jobApproval ref="positionApproval3" :activeTab="activeName" v-if="activeName === '17'" pageName="PositionAdjustment" />
			</el-tab-pane> -->
			<el-tab-pane label="职位调整" name="18" v-if="permissionControl('PositionAdjustment')">
				<jobApproval ref="positionApproval3" :activeTab="activeName" v-if="activeName === '18'" pageName="PositionAdjustment" />
			</el-tab-pane>
			<el-tab-pane label="岗位调整" name="19" v-if="permissionControl('PostAdjustment')">
				<jobApproval ref="positionApproval4" :activeTab="activeName" v-if="activeName === '19'" pageName="PostAdjustment" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import jobApproval from './jobApproval/query.vue';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'organizationalList',
	components: { jobApproval },
	props: { pageName: {} },
	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('positionApprovalData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '15';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: '15', key: 'RankAdjustment' },
			{ name: '16', key: 'JobFamilyAdjustment' },
			{ name: '17', key: 'PositionAdjustment' },
			{ name: '18', key: 'PostAdjustment' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'positionApprovalData',
			JSON.stringify({
				activeName: this.activeName
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>

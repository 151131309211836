<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="职位展示" name="1" v-if="permissionControl('JobPresentation')">
				<positionView ref="position1" :activeTab="activeName" v-if="activeName === '1'" pageName="JobPresentation" />
			</el-tab-pane>
			<el-tab-pane label="职位设置" name="2" v-if="permissionControl('JobSetting')">
				<positionSetting ref="position2" :activeTab="activeName" v-if="activeName === '2'" pageName="JobSetting" />
			</el-tab-pane>
			<el-tab-pane label="职位审批" name="3" v-if="permissionControl('JobApproval')">
				<positionApproval ref="position3" :activeTab="activeName" v-if="activeName === '3'" pageName="JobApproval" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import positionApproval from './positionApproval.vue';
import positionSetting from './positionSetting.vue';
import positionView from './positionView.vue';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'organizationalList',
	components: { positionApproval, positionSetting, positionView },

	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('positionData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'JobPresentation' },
			{ name: '2', key: 'JobSetting' },
			{ name: '3', key: 'JobApproval' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'positionData',
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`position${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>

<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="体系导入" name="1" v-if="permissionControl('IntroductionSystem')">
				<systemImport ref="positionSetting1" :activeTab="activeName" v-if="activeName === '1'" pageName="IntroductionSystem" />
			</el-tab-pane>
			<el-tab-pane label="职族类" name="2" v-if="permissionControl('ClassOccupation')">
				<jobType ref="positionSetting2" :activeTab="activeName" v-if="activeName === '2'" pageName="ClassOccupation" />
			</el-tab-pane>
			<el-tab-pane label="职位类" name="3" v-if="permissionControl('JobCategory')">
				<positionAllocation ref="positionSetting3" :activeTab="activeName" v-if="activeName === '3'" pageName="JobCategory" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import systemImport from './positionManagement/import.vue';
// import familyClass from './familyClass.vue';
// import positionClass from './positionClass.vue';
import jobType from './jobType/jobType.vue'; // 职族类
import positionAllocation from './positionAllocation/positionAllocation.vue'; // 职位类

// import orgConfig from '../orgConfig.vue';
export default {
	name: 'organizationalList',
	props: {
		pageName: {}
	},
	components: { systemImport, jobType, positionAllocation },

	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('positionSettingData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: '1', key: 'IntroductionSystem' },
			{ name: '2', key: 'ClassOccupation' },
			{ name: '3', key: 'JobCategory' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'positionSettingData',
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`positionSetting${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>

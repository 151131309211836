<!--  岗位  -->
<template>
	<div class="container">
		<baseTable ref="baseTable" @request="getTableData" :queryItem="queryItem" :columns="tableCols" :otherBtns="otherBtns" showOrder />
		<!-- 新增岗位  -->
		<el-dialog
			:title="dialogTitle"
			top="30vh"
			width="900px"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				:formItems="dialogItems"
				@cancel="dialogVisible = false"
				@submit="submitForm"
				:defaultData="formModel"
				style="margin: 20px 120px"
				:type="'edit'"
			>
				<div class="addClass" @click="addJobBook">
					<i class="el-icon-plus" style="margin-right: 4px"></i>{{ formType === 'edit' ? '修改' : '添加' }}岗位说明书
				</div>
			</formItem>
		</el-dialog>
		<!-- 新增岗位说明书  -->
		<addJob :visible="dialog2Visible" :formType="formType" :jobId="jobId" :orgId="orgId" @close="handleCancleAdd" />
	</div>
</template>

<script>
/* 岗位 */
import baseTable from '@/views/components/components-base-table';
import formItem from '@/views/components/components-form-item/index.vue';
import addJob from './addJob';
// import { queryItem } from './pageConfig.js';
// import moment from 'moment';
export default {
	name: 'organizationalFunctions',
	components: {
		baseTable,
		formItem,
		addJob
	},

	props: { pageName: {} },
	computed: {},
	data() {
		return {
			tableCols: [
				{
					label: '组织',
					key: 'orgName'
				},
				{
					label: '职位',
					key: 'officeName'
				},
				{
					label: '岗位',
					key: 'jobName'
				},
				{
					label: '岗位说明书',
					key: 'gwsms',
					render: (row) => (
						<div>
							{this.permissionControlBtns(this.pageName, 'JobBookView') && (
								<el-button type="text" onclick={(e) => this.handleView(row, e)}>
									详情
								</el-button>
							)}
						</div>
					)
				},
				{
					label: '操作',
					key: 'cz',
					render: (row) => (
						<div>
							{this.permissionControlBtns(this.pageName, 'Edit') && (
								<el-button type="text" onclick={(e) => this.handleEdit(row, e)}>
									编辑
								</el-button>
							)}
							{this.permissionControlBtns(this.pageName, 'Delete') && (
								<el-button
									type="text"
									style={'color:rgb(245, 108, 108); '}
									// disabled={this.getDelDisabled(row, inx)}
									onclick={(e) => this.handleDel(row, e)}
								>
									删除
								</el-button>
							)}
						</div>
					)
				}
			],
			queryItem: [
				{ label: '岗位', type: 'input', key: 'jobName', span: 6 },
				{
					label: '组织',
					key: 'orgId',
					type: 'tree',
					async: () => this.$axios.post('/v1/org/list', { request: {} }),
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					span: 6,
					filter: true
				},
				{ label: '职位', key: 'officeName', type: 'input', span: 6 }
			],

			otherBtns: [
				this.permissionControlBtns(this.pageName, 'Add') && {
					label: '新增岗位',
					icon: 'el-icon-plus',
					style: { float: 'right' },
					click: () => {
						this.dialogTitle = '新增岗位';
						this.formType = 'add';
						this.formModel = {};
						this.dialogVisible = true;
					}
				}
			].filter((v) => v),
			dialogVisible: false,
			formModel: {},
			dialogList: [{ dutyName: '职责1' }],
			dialogItems: [
				{ label: '岗位名称', key: 'jobName', type: 'input', span: 12, rules: { required: true, message: '请输入', trigger: 'blur' } },
				{
					label: '组织',
					key: 'orgId',
					type: 'tree',
					options: this.$store.state.setData?.options?.orgId || [],
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					filter: true,
					rules: { required: true, message: '请选择', trigger: 'change' },
					span: 12,
					change: async (formData, _, item) => {
						this.$set(formData, 'officeId', '');
						this.$set(formData, 'supJobId', '');
						this.$set(formData, 'officeName', '');
						this.$set(formData, 'supJobName', '');
						const res = await this.getOfficeByOrgId(formData.orgId);
						const jobList = await this.getSupJobList(formData.orgId);

						item.forEach((it) => {
							if (it.key === 'officeId') {
								this.$set(
									it,
									'options',
									res?.map(({ id, officeName }) => ({ dictId: id, dictName: officeName }))
								);
							}
							if (it.key === 'supJobId') {
								this.$set(
									it,
									'options',
									jobList?.map(({ id, jobName }) => ({ dictId: id, dictName: jobName }))
								);
							}
						});
					}
				},
				{
					label: '职位',
					key: 'officeId',
					showKey: 'officeName',
					type: 'select',
					span: 12,
					rules: { required: true, message: '请选择', trigger: 'blur' }
				},
				{
					label: '上级岗位',
					key: 'supJobId',
					showKey: 'supJobName',
					type: 'select',
					span: 12
				}
				// {
				// 	label: '岗位编制',
				// 	key: 'employeesNumber',
				// 	type: 'inputNumber',
				// 	// rules: { required: true, message: '请选择', trigger: 'change' },
				// 	min: 0,
				// 	// max: 20,
				// 	step: 1,
				// 	span: 12,
				// 	precision: 0
				// }
			],
			dialogSenModel: {},
			// 资格
			dialogSenItems: [
				// { label: '任职资格', key: 'rzzg', type: 'empty' },
				{ label: '基本条件', key: 'jbtj', type: 'empty', style: 'height:40px', labelPosition: 'left' },
				{ label: '教育水平', key: 'educational', type: 'input', span: 8 },
				{ label: '工作经验', key: 'experience', type: 'input', span: 8 },
				{ label: '语言能力', key: 'tongue', type: 'input', span: 8 },
				{ label: '专业知识', key: 'major', type: 'input', span: 8 },
				{ label: '个人素质', key: 'quality', type: 'input', span: 8 },
				{ label: '其他', key: 'other', type: 'input', span: 8 },
				{ label: '岗位胜任要求', key: 'gwyq', type: 'empty', style: 'height:40px', labelPosition: 'left' },
				{ label: '知识', key: 'knowledge', type: 'textarea', rows: 3, autosize: false, span: 12 },
				{ label: '技能', key: 'skill', type: 'textarea', rows: 3, autosize: false, span: 12 }
				// { label: '', labelWidth: '30px', key: 'detail', type: 'input', span: 16, placeholder: '详情' }
			],
			form2Model: {},
			dialogTitle: '添加',
			formType: 'edit',
			dialog2Visible: false,
			jobId: '',
			orgId: ''
		};
	},
	created() {},
	mounted() {},
	methods: {
		getTableData(params, callback) {
			callback(this.$axios.post('/v1/profession/job/list', params));
		},
		// 编辑
		handleEdit(row, e) {
			e.stopPropagation();
			this.formType = 'edit';
			this.dialogTitle = '编辑';
			this.formModel = { ...row };
			this.orgId = row.orgId;
			this.dialogVisible = true;
		},
		// 删除
		handleDel(row, e) {
			e.stopPropagation();
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/profession/job/del', {
						request: { id: row.id },
						sign: ''
					})
					.then((res) => {
						if (res && res.code == 0) {
							this.$message.success('删除成功!');
							this.$refs.baseTable?.handleSearch();
						} else {
							// this.$message.error(res.message)
						}
					});
			});
		},
		// 提交岗位
		async submitForm(form) {
			const f = form || this.$refs.formItem.form;

			return new Promise((resolve, reject) => {
				f.validate(async (valid) => {
					if (valid) {
						const params = { ...this.formModel };

						if (Array.isArray(params.familyId)) {
							const arr = params.familyId;

							params.familyId = arr[0];
							params.classId = arr[1];
							params.subclassId = arr[2];
						}
						this.$axios
							.post('/v1/profession/job/save', { request: params })
							.then((res) => {
								if (res.code) {
									return;
								}
								resolve(res);
								this.dialogVisible = false;
								this.$message.success('保存成功');
								this.$refs.baseTable?.handleSearch();
							})
							.catch(() => {
								this.dialogVisible = false;
								reject(null);
							});
					}
				});
			});
		},
		handleCancleAdd() {
			this.dialog2Visible = false;
			this.jobId = '';
		},
		// 添加修改岗位说明书
		addJobBook() {
			if (this.formType === 'edit') {
				this.dialogTitle = '修改岗位说明书';
				this.jobId = this.formModel.id;
				this.formModel = {};
				this.dialogVisible = false;
				this.dialog2Visible = true;
				return;
			}
			this.$refs.formItem.form.validate(async (valid) => {
				if (valid) {
					this.$confirm('添加岗位说明书将自动保存当前岗位信息，是否继续？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.submitForm().then((res) => {
							if (res?.code) {
								return;
							}
							// 获取说明书详情
							// this.getJobDetailById(res);
							this.dialogTitle = `${this.formType === 'edit' ? '修改' : '添加'}岗位说明书`;
							this.formModel = {};
							this.jobId = res;
							this.dialogVisible = false;
							this.dialog2Visible = true;
						});
					});
				}
			});
		},
		// 根据岗位查询员工或根据员工查询岗位
		getDetailInfo(data) {
			return this.$axios
				.post('/v1/org/findJobStaffInfo', { request: data })
				.then((res) => {
					if (res.code) {
						return {};
					}
					return res;
				})
				.catch(() => {});
		},
		// 通过组织获取职位下拉
		getOfficeByOrgId(id) {
			return this.$axios
				.post('/v1/profession/findOfficeByOrgId', { request: { orgId: id } })
				.then((res) => {
					if (res.code) {
						return {};
					}
					return res;
				})
				.catch(() => {});
		},
		// 详情
		handleView(row, e) {
			e.stopPropagation();
			this.formType = 'view';
			this.jobId = row.id;
			this.orgId = row.orgId;
			this.dialog2Visible = true;
		},
		getSupJobList(orgId) {
			if (!orgId) {
				return Promise.resolve([]);
			}
			return this.$axios
				.post('/v1/profession/findSupJobListByOrgId', { request: { orgId, id: this.formModel.id } })
				.then((res) => {
					if (res.code) {
						return [];
					}
					return res;
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	// padding: 20px;
	.addClass {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 30px 0;
		justify-content: center;
	}
	.addFunctions {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 30px 0;
		justify-content: center;
	}
}
</style>
